.new-service-provider-select{
    margin: 0 3rem;
}
.new-service-provider-select .filters{
    margin: 1rem 0;
    text-align: center;
}
.new-service-provider-select .filters input{
    margin: 0 1rem;
    text-align: center;
    border-radius: .5rem;
}
.new-service-provider-select .filters div{
    width: fit-content;
    display: inline-block;
}
.jobTypeSelector{
    margin: 0 1rem;
    text-align: center;
    border-radius: .5rem;
}
.new-service-provider-select .result{
    width: 100%;
    border-radius: 1rem;
    font-weight: 700;
    padding: .5rem;
    border-collapse: collapse;
    background: rgba(222, 194, 160, 0.7);
    margin: 2rem 0;
}
.new-service-provider-select .result tr.result-row:hover{
    box-shadow: inset 0 0 100px 100px rgba(187, 187, 187, 0.3);
}
.new-service-provider-select .result tr.selected{
    box-shadow: inset 0 0 100px 100px rgba(77, 245, 105, 0.3);
}
.new-service-provider-select .result tr>td{
    padding: .5rem;
}
.new-service-provider-select .result tr:nth-child(even) {
    background: var(--interv-bg-even);
}
.new-service-provider-select .result td {
    text-align: center;
}

.new-service-provider-select .result tr:last-child {
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
}

.more-button.validation-button{
    background: #1a87ff;
    border: #4099f6 1px solid;
}
.more-button.validation-button:hover{
    background: #4099f6;
    border: #4099f6 1px solid;
}