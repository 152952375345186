.site-bean{
  margin: 2rem 7rem ;
}

.site-bean .site > div{
  border: var(--div-border) 1px solid;
  margin: auto;
  width: fit-content;
  display: grid;
  grid-template-areas: "a b c";
  grid-gap: 1rem;
  justify-content: space-between;
  border-radius: 2rem;
  padding: 2rem 1rem;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.2) 19%, rgba(244, 243, 243, 0.09) 59%);
  backdrop-filter: blur(5px);
}

.site-bean .site > div p{
  background-color: rgba(255, 255, 255, 0.11);
  border: var(--div-border) solid 1px;
  padding: .5rem 1rem .5rem 1rem;
  border-radius: .5rem;
  width: fit-content;
  grid-area: b;
}

.site-bean .site > div p li{
  width: fit-content;
  padding: .2rem 1rem;
  background-color: rgba(0, 0, 0, 0.18);
  border: solid 1px rgb(255, 255, 255);
  border-radius: .5rem;
  list-style: circle;
}

.site-bean .site > div img{
  max-width: 30rem;
  max-height: 22rem;
  border-radius: .5rem;
  margin: auto;
  grid-area: a;
}

.site-bean .site > div p{
  font-size: 1.2rem;
}

.site-bean .interventions img:hover{
  transition: .5s ease all;
  background-color: rgba(255, 255, 255, 0.91);
}

.site-bean .interventions img{
  background-color: rgba(255, 255, 255, 0.2);
  padding: .3rem .5rem;
  border: 1px white solid;
  border-radius: .7rem;
  transition: .5s ease all;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.36);
}

.site-bean .interventions table{
  border-collapse: collapse;
  box-sizing: border-box;

}

.site-bean .interventions td,.site-bean .interventions th{
  border: solid 2px #3636366b;
  padding: 10px;
  background-color: #FFFFFF54;
  color: #000000de;
}

.site-bean .interventions{
  margin: 1rem auto;
  border: var(--div-border) 1px solid;
  border-radius: 2rem;
  width: 80%;
  padding: 2rem 1rem;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.25) 19%, rgba(244,243,243,0.15) 59%);
  backdrop-filter: blur(5px);
}

.site-bean .interventions .table{
  margin: auto;
  width: 90%;
  overflow: auto;
}