.devis-bean{
    width: 100%;
    display: grid;
    gap: 1rem;
    grid-template-areas: "m t a b b x"
                         "m c c c c x"
                         "m f f f f x";
}
.share{
    grid-area: t;
}
.devis-bean h2{
    grid-area: a;
    color: var(--devis-bean);
}
.devis-bean h1{
    grid-area: t;
    margin: auto;
}

.devis-bean a{
    color: black;
}

.devis-bean > .devis-container {
    grid-area: b;
    padding: .5rem 1rem;
    border: 1px solid rgba(0, 0, 0, 0.185);
    border-radius: 1rem;
    width: fit-content;
    height: fit-content;
    background-color: rgba(255,255,255,0.43);
}

.devis-bean .devis-head{
    width: max-content;
    padding: .2rem 0;
    height: fit-content;
    align-items: center;
    display: grid;
    color: black;
}

.devis-bean .devis-head input[type=number]{
    -webkit-appearance: textfield;
    -moz-appearance: textfield;

}

.devis-bean .devis-head div.tabinput{
   width: 100%;
    box-sizing: border-box;

}


.devis-bean .devis-head > div{

}


/*.devis-bean .devis-head > div div{*/
/*    text-align: center;*/
/*    margin:  .1rem .5rem;*/
/*    padding: .1rem .3rem;*/
/*    height: fit-content;*/
/*    border-radius: .3rem;*/
/*    min-width: 6rem;*/
/*    width: fit-content;*/
/*    bottom-border: rgba(133, 133, 133, 0.49) 1px solid;*/
/*}*/

.devis-bean .devis-head > div div > span{

}


.devis-bean .devis-comments{
    grid-area: f;
    display: block;
    padding: 1rem;
    margin-bottom: 3rem;
    min-height: 180px;
    height: fit-content;
    width: 90%;
    border-radius: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.185);
    background-color: rgba(83, 169, 190, 0.514);
    backdrop-filter: blur(1px);
}
.devis-bean .CmdRef:before{

}


.devis-bean .devis-comments .textedit{
    min-height: 250px;
    width: 77vw;
    margin: 0 auto;
    border-radius: .3rem;
    cursor: text;
}

.devis-bean .devis-comments textarea{
    max-width: 77vw;
    min-width: 77vw;
    margin: 0 auto;
    border-radius: 0.7rem;
    font-family: Rubik;
    font-size: 1rem;
    background-color: rgba(245, 245, 245, 0.1);
}

.devis-bean .devis-comments h3{
    margin: 0
}
.devis-bean .devis-comments div{
    height:100vh;
}

.devis-bean .MuiIconButton-root.validation-button:hover {
    background-color: grey;
    opacity: 10%;
    transition: .5s ease-in-out all;
    text-decoration: line-through;
}

.devis-bean .MuiIconButton-root.validation-button {
    height: fit-content;
    float: right;
    width: auto;
    display: block;
    margin: auto;
    opacity: 40%;
    transition: .5s ease-in-out all;
}
/*.devis-bean .devis-head{*/
/*    display: grid;*/

/*}*/
.devis-bean .devis-body {
    display: inline-grid;
    gap: .3rem;
    grid-column: 2;
    grid-row: 3;
}
.devis-bean .devis-head .tabrow:first-child {
    height: 1.7rem;
}
.devis-bean .devis-head .tabrow {
    display: table-row;
    width: 100%;
    padding: .3rem;
    border-radius: .3rem;
    background-color: var(--profile-name-background);
    border: rgba(122, 122, 122, 0.39) 1px solid;
}
.devis-bean .devis-head .tabcell{
    display: inline-flex;
    justify-content: right;
    align-items: center;
    text-align: right;
    width: 7rem;
    margin:auto 1rem;
}
.devis-bean .devis-head .tabcell:nth-child(odd):after {
    margin-left: .3rem;
    content: ":";
}
.devis-bean .devis-head .tabcell:nth-child(odd) {
    font-weight: bold;



}

.devis-bean .devis-head .tabcell .tabinput{
    display: inline-flex !important;
    margin: auto;
    min-height: 1rem;
    min-width: 3rem;
    background-color: rgba(0, 0, 0, 0.21);
    border-radius: .3rem;
    color: var(--link);
}


/*.devis-bean .devis-head > div div:before{*/
/*    margin: auto .7rem;*/
/*    content: attr(title);*/
/*    font-weight: bold;*/
/*    min-width: .5rem;*/
/*    height: 2rem;*/
/*    border-bottom: rgba(105, 105, 105, 0.52) 1px solid;*/
/*    border-radius: .3rem;*/
/*}*/


.devis-bean .devis-intervention{
    grid-area: c;
}
.devis-bean .devis-intervention table th{
    border: solid 1px var(--ul);
    padding: 0.2rem;
    font-size: 1rem;
    border-radius: .5rem .5rem 0 0;
    background-color: var(--modal-background);
    color: var(--interv);

}

.devis-bean .devis-intervention table {
    margin: 1rem 0;
    border-collapse: separate;
    border-spacing: 1px;
    border: solid var(--editable-border) 1px;
    border-radius: .7rem .7rem .3rem .3rem;
    width: 92%;
    padding: .2rem .1rem;
    text-align: center;

}
.devis-bean .devis-intervention td,.devis-bean .devis-intervention th {
    border: solid var(--editable-border-hover) 1px;
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.3);
    color: #3f3f3f;
}

{

}

.devis-bean .devis-sideBar{
    grid-area: m;
    margin-top: 3rem;
    box-shadow: 0px 0px  15px 0px rgba(0, 0, 0, 0.185) ;
    border-radius: 5rem;
    height: fit-content;
    display: inline-grid;
    width: 5rem;
    margin-left: 0px;
}