.share{
    text-align: center;
}

.share .choice-button{
    display: inline-block;
    background: #638ab0;
    border: #405364 1px solid;
}

.searchForShare{
    border: #000 1px solid;
    border-radius: 1rem;
    margin: 2rem 0;
}