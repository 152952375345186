.planning-map{
    width: 100%;
}
.planning-map .map {
    width: 100%;
    height: 92%;
    border:2px solid rgba(0, 0, 0, 0.2);
}
.planning-map .plan-search >*{
    height: 8%;
    box-sizing: border-box;
}

.planning-map .planning-card{
    background: rgba(255, 255, 255, 0.856);
    border-radius: .5rem;
    padding: .1rem;
    box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.2);
    width: max-content;
    font-family: rubik, sans serif;
    font-size: .8rem;
    position: absolute;
    z-index: 10;
    transition: all .2s ease;
}

.planning-map .planning-card div{
    margin: .3rem;
    background-color: rgba(87, 120, 170, 0.123);
    padding: .2rem;
    border-radius: .5rem;
}

.planning-map .planning-card ul{
    background-color: rgba(238, 255, 0, 0.363);
    font-weight: 900;
    cursor: pointer;
    padding: .4rem .8rem;
    list-style: none;
}

.planning-map .planning-card .intervention-type{
    color: white;
}

.planning-map .planning-card ul::before{
    content: url("../images/contact-interaction.png");
}
