.commande-form{
    min-width: 100%;

    display: grid;
    grid-gap: 0.2%;

}
.commande-form::after {
    width: 100%;
    height: 100%;
    text-align: center;
    transition: all .35s;
}
.commande-form >button{
    display: block;
    margin: auto;
}
.commande-form img{
    margin: .5rem;

}
.commande-form .commande-head{
    display: flex;
    justify-content: space-between;
    background-color: rgba(255, 255, 255, 0.377);
    border-radius: 3rem;
    padding: 0.5rem 0.3rem 0.5rem 2rem;
}

.commande-form .validation-button{
    background: #359f00;
    max-width: 16rem;
    margin: .3rem auto;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.23);
    padding: .2rem 4.2rem;
    border-radius: 2rem;
    font-family: nunito;
    font-size: 1.2rem;
    border: rgba(26, 194, 0, 0.75) 1px solid;
    color: rgb(255, 255, 255);
    transition: all .4s ease-in-out;
}

.commande-form .validation-button:hover {
    background: rgba(0, 175, 0, 0.9);
    box-shadow: 0px 3px 10px rgba(255, 255, 255, 0.41);
    color: #ffffff;
    font-weight: 900;
    text-shadow: rgba(0, 0, 0, 0.72) 0px 0px 3px;
    transition: all .2s ease-in-out;
    border: greenyellow 1px solid;
    border-radius: 2rem;

}.commande-form .refresh-button:hover {
     background: rgba(0, 143, 175, 0.93);
     box-shadow: 0px 3px 10px rgba(255, 255, 255, 0.41);
     color: #ffffff;
     font-weight: 900;
     text-shadow: rgba(0, 0, 0, 0.72) 0px 0px 3px;
     transition: all .2s ease-in-out;
     border: rgba(47, 255, 248, 0.75) 1px solid;
     border-radius: 2rem;
 }

.commande-form .cancel-button {
    background: var(--cancel-button);
    max-width: 16rem;
    padding: .3rem 3.5rem;
    margin: .3rem auto;
    border-radius: 2rem;
    font-family: nunito;
    transition: all .4s ease-in-out;
    font-size: 1.2rem;
    color:  var(--cancel-button-text);
}

.commande-form .cancel-button:hover {
    background: var(--cancel-button-hover);
    transition: all .2s ease-in-out;
    border-radius: 2rem;
    color:  var(--cancel-button-text);
}

.commande-form .commande-data{
    background-color: rgba(70, 167, 180, 0.356);
    border-radius: 2rem;
    padding: 1rem 1rem 5rem 1rem;
    grid-gap: 0.7rem;
    display: grid;
    grid-template-columns: repeat(2,2fr);
    border: 1px solid rgba(255, 255, 255, 0.445);
}

.commande-form input{
    font-family: rubik;

}

.commande-form .cmdCreatInput{
    padding: 1rem;
    max-height: 3rem;
    max-width: 16rem;
    background-color: whitesmoke;
}
.commande-form .cmdCreatInput select{
    margin-right: 2rem;
}

.commande-form .createSite .search__control{
    height: 2.8rem;
    max-width: 16rem;
    background-color: whitesmoke;
}

.commande-form .createSite .search__control svg{
    color: black;
    transform: scale(.75);

}
.commande-form .createSite .search__placeholder{
    color: black;
}

.commande-form .createSite .search__value-container{
    margin-left: .5rem;
    cursor: text;
}
.commande-form .commande-data >div{
    background: linear-gradient(#ebfc0067, #d9ff006c);
    padding: 0.9rem;
    border: 1px solid rgba(255, 255, 255, 0.445);
    border-radius: 1rem;
}

.commande-form .commande-data >div:first-child{
    background: linear-gradient(#d0ff003d, #eeff00a8);
    padding: 0.9rem;
    border: 1px solid rgba(255, 255, 255, 0.445);
    border-radius: 1rem;
}
.commande-form .commande-data >div:last-child{
    background: linear-gradient(#d0ff003d, #eeff00a8);
    padding: 0.9rem;
    border: 1px solid rgba(255, 255, 255, 0.445);
    border-radius: 1rem;
}

.commande-form .commande-data div:before{
    content: attr(title);
    display: block;
    font-size: 0.8rem;
    color: #000000d5;
}

.commande-form .search .search__control{
    height: 2.8rem;
}

.commande-form .search__menu{
    top: 2rem;
    color: black;
}

.commande-form > select {
    display: block;
    height: .5rem;
    font-size: 16px;
    font-family: rubik, sans-serif;
    font-weight: 700;
    color: #444;
    line-height: 1.3;
    padding: .6em 1.4em .5em .8em;
    width: 15rem;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0;
    border: 1px solid #aaa;
    box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
    border-radius: .5em;
    background-color: #fff;
}

.commande-form .commande-data input{
    padding: 0.7rem;
    border: solid;
    border-width: 1px;
    border-color: rgba(161, 161, 161, 0.61);
    width: 14.5rem;
    border-radius: .3rem;
}

.commande-form .commande-data .datetimeInput{
    padding: .715rem;
    width: 14.5rem;
}

.commande-form .commande-data select{
    padding: .68rem;
    border: solid;
    border-width: 1px;
    border-color: rgba(161, 161, 161, 0.61);
    min-width: 16rem;
    border-radius: .3rem;
}

.commande-form .commande-data ::-webkit-input-placeholder{
    color: rgb(139, 139, 139);
    font-size: 1.2em;
    font-family: rubik;
}

.commande-form .commande-data select{
    color: rgb(0, 0, 0);
    font-size: 1em;
    font-family: rubik;

}

.commande-form .commande-data input:focus,.commande-form .commande-data select:focus{
    outline: rgb(80, 143, 214);
    outline-style: auto;
    outline-width: 1px;
}



