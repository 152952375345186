

:root{
    --loading-gradient:linear-gradient(90deg, rgba(213, 213, 213, 0), rgba(225, 96, 4, 0.24), rgba(255, 255, 255, 0.04), rgba(0, 0, 0, 0.16));
    --text: rgba(0,0,0,0.9);
    --link: #794d11;
    --background-gradient: url("./images/background.png");
    --background-image: url("./images/background2.png");
    --logo: url("./images/ebat-logo.png");

    --validation-button: rgb(0, 143, 0);
    --validation-button-hover: rgb(11, 168, 0);
    --validation-button-text: #f0fff0;

    --td-cell: rgba(255, 255, 255, 0.25);
    --td-cell-hover: rgba(255, 255, 255, 0.52);
    --td-cell-border-hover: rgba(255, 255, 255, 0.18);
    --editable-border: rgba(255, 255, 255, 0.74);
    --editable-border-hover: rgb(255, 255, 255);
    --tab-font-weight: 600;

    --cancel-button: rgb(133, 4, 4);
    --cancel-button-hover: rgba(199, 0, 0, 0.78);
    --cancel-button-text: rgb(240, 255, 240);

    --shadow: rgba(0, 0, 0, 0.2);

    --div-border: rgba(0, 0, 0, 0.274);
    --nav-bar: linear-gradient(90deg, rgba(0,0,0,0), rgba(48, 80, 117, 0.74), #305075, #0a2642);
    --navbar-brightness: brightness(1.3);
    --profile-name-background: rgba(256,256,256,.4);
    --profile-name-text-color: #000;
    --green-rounded-form-div: rgba(107,167,82,0.7);
    --grid-border: rgba(135,53,0,0.5);


    --app-header-background: #282c34;
    --app-header: #fff;

    --select: rgba(255, 255, 255, 0.8);
    --select-border: #00000060;
    --input-color: rgba(255, 255, 255, 0.8);
    --input-border-color: #00000060;

    --glow-border: #cdca00;
    --glow: linear-gradient(rgba(255, 225, 0, 0.18), rgba(255, 217, 0, 0), rgba(255, 225, 0, 0.49));

    --time-estimation-div: rgba(0, 128, 128, 0.301);
    --cbox-background: rgba(0, 0, 0, 0.281);
    --cbox-color: rgb(255, 255, 255);
    --supply-material: rgba(231, 231, 231,0.5);
    --supply-machinery: rgba(2055,222,116,0.5);
    --logo-effect: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0));

    --highlighted-text-shadow: rgba(0, 0, 0, 0.13) 0px 0px 5px;
    --no-result: rgba(0, 0, 0, 0.27);
    --stepflat-color: linear-gradient(rgb(177, 216, 255), rgba(132, 175, 231, 0.5), rgba(27, 168, 229, 0.11));
    --stepflat-color-hover: rgba(123, 244, 255, 0.89);
    --interv-bg-even: rgba(255, 255, 255, 0.24);
    --li-odd-color: rgba(119,119,119,0.15);
    --devis-document: rgba(132,193,106,0.3);
    --devis-machineries: rgba(255,222,116,0.4);
    --supply-doc: #34a3003f;
    --admin-doc: rgba(0, 68, 214, 0.192);
    --row-hover-highlight: linear-gradient(90deg, rgba(255, 255, 255, 0.18), rgba(255, 255, 255, 0.89), rgba(255, 255, 255, 0.13), rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0));


    --hover-row-text-tab: rgba(0, 0, 0, 0.21) 0px 0px 2px;
    --ul: rgba(255, 255, 255, 0.69);
    --modal-background: rgba(255, 255, 255, 0.44);
    --modal-border-color: rgba(255, 255, 255, 0.2);

    --comment-div-color: rgba(0, 123, 155,0.4);
    --devis-bean: rgb(126,65,27);
    --devis-data: rgba(110,0,5,0.2);
    --commande: rgba(181,218,166,0.4);

    --th: rgb(225, 96, 4);
    --interv: rgba(139, 85, 17, 0.8);
    --interv-thead: rgba(139, 85, 17, 0.6);
     --pager-border: #c2770e;
    --invert: 0;

  --graph-color-1: rgba(0, 41, 211, 0.84);
  --graph-color-2: rgba(121, 39, 211, 0.88);
  --graph-color-3: rgba(238, 44, 182, 0.8);
  --graph-color-4: rgba(182, 0, 0, 0.95);

}
