/*      Command&Quotation rules       */
.devis-bean {
    align-items: center;
    justify-content: start;
    grid-row-gap: .3rem;
    column-gap: .5rem;
    padding-left: 5%;
    padding-right: 5%;
    margin-bottom: 5rem;
    height: fit-content;
    width: unset;
}

.stepper {
    grid-column: span 10;
}

.devis-bean .references {
    grid-column: span 3;
    text-align: center;
    align-self: center;
    max-height: 20vh;
}

.devis-bean .references .ref-edit:before {
    content: "Référence : ";

}
.devis-bean .references .ref-edit {
    min-width: 12rem;
    padding: 0.3rem;
}

.devis-bean .documents {
    grid-column: span 2;
    flex-wrap: wrap;
    max-height: 20vh;
    overflow: auto;
}

.devis-bean .documents .flex-column {
    width: 5rem;
}

.devis-bean .infos {
    grid-auto-rows: 1fr;
    grid-column: span 5;
    padding: 1rem;
}

.devis-bean .details, .devis-bean .comments {
    grid-column: span 10;
    padding-bottom: 2rem;
    text-align: left;
    align-items: start;
}

.comments textarea {
    background-color: rgba(255, 255, 255, 0.35);
}

.comments .textedit {
    font-weight: bold;
    height: auto !important;
    min-height: 88px;
}

.comments span {
    font-weight: bold;
}

.devis-bean .tabrow {
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 1rem;
    justify-items: start;
    border-image-repeat: initial;
    align-items: center;
    background-color: var(--interv-bg-even);
}

.devis-bean .tabrow:nth-child(2n+1) {
    background-color: var(--td-cell-hover);
}

.devis-bean .tabrow input {
    max-width: 50%;
}

.devis-bean .tabcell {
    display: inline-flex;
    padding: .5rem;
    font-size: 1.2rem;
    font-weight: 600;
    align-items: stretch;
}

.tabcell .tabinput {
    border-radius: 0.3rem;
    height: 2rem;
    min-width: 5.2rem;
    padding: 0.2rem;
    margin: 0 .5rem;
    text-align: center;
}

.devis-bean .secondary-section {
    border: 2px white solid;
    border-radius: 1.8rem;
}

.devis-bean .tabcell:nth-child(even) {
    text-align: end;
}


.criteria-bar {
    position: absolute;
    top: 50%;
    transform: scale(.8) translate(-60%, -50%);
    background-color: rgba(256, 256, 256, .1);
    backdrop-filter: blur(10px);
    box-shadow: 0 0 .5rem rgba(0, 0, 0, 0.2);
    border-radius: 0 5rem 5rem 0;
    opacity: .7;
    transition: all 0.3s ease-in-out .5s;
    z-index: 5;
    left: -.2rem;
}
.criteria-bar .margin-content {
    display: flex;
    flex-direction: column;
}

.criteria-bar:hover {
    opacity: 1;
    transform: scale(.8) translate(-20%, -50%);
    transition: all .05s ease-in-out;
}
.criteria-bar a .add-map button:disabled {
    opacity: 0.3;
    filter: grayscale(100%);
}

.devis-bean .details .quotation > div.flex-column {
    align-items: stretch;
}

.devis-bean .chapter-title {
    font-size: 1.3rem;
    font-weight: 600;
    background: whitesmoke;
    height: 3rem;
    align-content: center;
}

.devis-bean .flex-column {
    gap : 1rem
}

.devis-bean .add-product:hover {

    transition: all ease .3s;
    padding: .2rem 7vw;
    width: 28rem;
    background-color: green;
}
.devis-bean .add-product {
    margin-right: auto;
    display: block;
    width: 23rem;
    padding: .2rem 3vw;
    transition: all ease .3s;
    background-color: rgba(0, 128, 0, 0.4);
    color: white;
}
.devis-bean .add-section:hover {
    transition: all ease .3s;
    padding: .2rem 10vw;
    background-color: green;
}

.devis-bean .add-section {
    margin-right: auto;
    display: block;
    transition: all ease .3s;
    background-color: rgba(0, 128, 0, 0.5);
    color: white;
}

.devis-bean img {
    max-height: 2rem;
}

.devis-bean .cancel {
    padding: 0;
}

.devis-bean .cancel.delete-chapter {
    transition: width .5s ease-in-out;
    height: 2rem;
    width: 2rem;
    margin-left: auto;
}
.devis-bean .cancel.delete-chapter:hover {
    transition: width .5s ease-in-out;
    padding: .5rem .5rem;
    width: 10vw;
    max-width: 10vw;
}

.devis-bean .cancel.delete-chapter:after {
    position: absolute;
    content: attr(title);
    padding: 0 .5rem;
    opacity: 0;
    transition: unset;
    font-size: medium;
}
.devis-bean .cancel.delete-chapter:hover:after {
    position: unset;
    opacity: 1;
    transition: opacity .3s ease .1s;
}

.devis-bean .cancel.delete-section {
    top: 0;
    right: 0;
}

.devis-bean .poi-chapter {
    position: relative;
    align-items: center;
    margin-bottom: .4rem;
}

.except-addBtn > *{
    border: 1px solid rgba(128, 128, 128, 0.5);
    border-radius: .2rem;
    justify-content: center;
    text-align: center;
}

.except-addBtn{
    grid-template-columns: 1fr 3fr 1fr 1fr 1fr 1fr 1fr;
}


.devis-bean .poi-chapter .comments {
    grid-column: span 11;
    margin-top: 1rem;
    align-items: stretch;
    text-align: start;
    width: inherit;
}

.devis-bean .table-header {
    background: var(--link);
    color: white;
    font-weight: bold;
    height: 3rem;
    margin: .2rem;
    border-radius: .3rem;
}

.devis-bean .poi-chapter .product-name {
    text-align: start;
    grid-column: span 2;
}

.devis-bean .poi-chapter .clientinfo-title {
    opacity: .3;
    font-size: 1.7rem;
    font-weight: 900;
}

.poi-chapter .as-link {
    font-size: 1.2rem;
    align-self: start;
}

.poi-chapter .selected {
    background: rgba(0, 255, 0, 0.3);
}

.devis-bean .draggable-block > div {
    position: relative;
}
.devis-bean .draggable-icon {
    width: 4rem;
    height: 4rem;
    z-index: 1;
    padding: .5rem;
}
.devis-bean .draggable-icon .anchor-icon span {
    height: 3px;
    background-color: var(--link);
    margin: 6px 0;
    display: block;
}

.quotation-form {
    gap: .5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

}

.quotation-form .remove-line {
    transform: scale(.6);
}

.flex-column {
    display: flex;
    flex-direction: column;
    gap: .5rem;
}
.flex-row {
    display: flex;
    flex-direction: row;
    gap: .5rem;
    align-items: center;
}

.grid {
    display: grid;
    width: 100%;
}


.site-quotation-row {
    border-radius: 1rem;
    background-color: #FFFFFF54;
    padding: .5rem;
    border: solid 1px var(--div-border);
}
.site-quotation-row.parent:hover {
    background-color: #ccc;
}


/* Style the accordion panel. Note: hidden by default */
.panel {
    padding: 0 18px;
    max-height: 0;
    overflow: auto;
    transition: max-height 0.4s ease-out;
}