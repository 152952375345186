.user-form{
    padding: 1rem 7rem;
    background-color: rgba(0, 128, 128, 0.185);
    border-radius: 10rem;
    margin: 1rem auto;
    align-items: center;
    display: flex;
    width: fit-content;
}
.user-form h2:before{
    content: url(../../images/new-user.png);
    transform: scale(1.5);
    opacity: .6;
    margin-right: 2rem;
    margin-left: -2rem;
}
.user-form h2{
    margin: 1rem;
    text-align: right;
    color: var(--text)
}
.user-form input,.user-form select{
    padding: .8rem;
    border-radius: .3rem;
    border: 1px solid rgba(128, 128, 128, 0.493);
    margin: .3rem;
}

.user-manager{
    /*display: inline-block;*/
    width: 30vw;
    margin: auto;
    padding: .1rem 1rem;
    background-color: rgba(0, 121, 121, 0.267);
    border: 1px solid rgb(255, 255, 255);
    border-radius: 1rem .2rem .2rem 1rem;
    float: left;
    height: 32rem;
}
.user-manager input{
    margin: 0.2rem;
    border-radius: .5rem;
    width: 100%;
    box-sizing: border-box;
    box-shadow: inset rgba(0,0,0,0.1) 1px 2px 19px ;
    background-color: rgba(255, 255, 255, 0.66);
    font-family: Rubik, sans-serif;
    font-size: 16px;
    padding: 14px 2px 12px 10px;
    border: 1px solid white;
    display: inline-block;
}
.user-manager ul{
    overflow-y: scroll;
    background-color: rgba(151, 231, 238, 0.26);
    border: 1px solid rgba(255, 255, 255, 0.397);
    border-radius: .7rem;
    height: 22vh;
}
.user-manager .selected{
    opacity: 1;
    background-color: rgba(255, 255, 255, 0.4);
    border: 1px solid white;
    font-weight: bold;
    text-decoration: underline;
}
.user-manager li:hover{
    cursor: pointer;
    opacity: .8
}
.user-manager li{
    transition: .5s ease all;
    width: 97%;
    background-color: rgba(255, 255, 255, 0.24);
    border-radius: .5rem;
    border: 1px solid rgba(255, 255, 255, 0.534);
    padding: .1rem;
    margin: .1rem .1rem;
    list-style: none;
    box-shadow: 3px 0 5px rgba(0, 0, 0, 0.137);
    display: block;
}
.user-manager li > div > div{
    padding: .2rem;
    border-radius: .3rem;
    margin: .1rem;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background-color: rgba(255, 255, 255, 0.2);

}
.user-manager li > div{
    color: rgb(0, 34, 61);
    display: inline-flex;
    width: -webkit-fill-available;
    margin: auto;
    justify-content: space-between;
}
.user-manager li > div > div > input{
    padding: .5rem;
    border-radius: .3rem;
    border: 1px solid gray;
    margin: .3rem;
}

#iconImg{
    background: none;
    content: url('../../images/check-user.png');
    margin: .2rem 1.5rem;
    display: inline-flex;
}
#settingsImg{
    border-radius: .3rem;
    border: 1px solid white;
    background-color: rgba(255, 255, 255, 0.41);
    content: url(../../images/pen-edit-icon.png);
    height: 1.3rem;
    display: inline-flex;
    margin: .5rem 1.5rem;
    cursor: pointer;

}

.user-manager .MuiIconButton-label:before{
    content: url("../../images/new-user.png");
    margin: .2rem;
}
.user-manager .MuiIconButton-label{
    width: 8rem;
    margin: .2rem;
}
.user-manager span{
    font-size: 1.2rem;
    margin: auto;
    font-weight: 550;

}
.user-manager .MuiIconButton-root:hover{
    background-color: rgba(27, 91, 131, 0.46);
}
.user-manager .MuiIconButton-root{
    margin: .8rem;
    float: right;
    padding: 0;
    background-color: rgba(67, 130, 143, 0.63);
    border: white 1px solid;
    width: 15rem;
}
.modUser input::placeholder{
    color: rgba(0, 0, 0, 0.62);
}
.modUser input{
    margin: 0.3rem;
    border-radius: .5rem;
    width: 22rem;
    box-sizing: border-box;
    box-shadow: inset rgba(0,0,0,0.1) 1px 2px 19px ;
    background-color: rgba(255, 255, 255, 0.88);
    font-family: Rubik, sans-serif;
    font-size: 16px;
    padding: 10px 2px 10px 10px;
    border: 1px solid white;

}

.MuiAccordionSummary-expandIcon{
    width: 4rem;
}
.modUser:before{
    content: url('../../images/pen-edit-icon.png');
    display: grid;
    font-size: 1.5rem;
    font-weight: bold;

}

.modUser{
    margin: 0 auto;
    border-radius: .2rem 1rem 1rem .2rem;
    width: 60%;
    max-width: 60%;
    height: 32.37rem;
    box-sizing: border-box;
    box-shadow: inset rgba(0,0,0,0.1) 1px 2px 19px ;
    background-color: rgba(64, 141, 121, 0.43);
    font-family: Rubik, sans-serif;
    font-size: 16px;
    padding: 2rem;
    border: 1px solid white;
    float: left;
    display: block;
    overflow: auto;
}
.userOptions> *{
    margin: .5rem;
}
.userOptions{
    padding: .2rem 1.5rem;
    margin: .4rem;
    display: flex;
    gap: 1rem;
    background-color: rgba(255, 255, 255, 0.38);
    border-radius: 2rem;
    align-items: center;
    flex-wrap: wrap;
}
.securityService label{
    margin: .5rem;
    vertical-align: super;
}
.securityService input{
    display: inline-block;
}
.fullWidth{
    width: 100%;
}
.userOptions input{
    height: 2rem;
    width: 2rem;
}

.MuiSvgIcon-root {
    width: 2rem;
    color: var(--text);
    height: 2rem;
    display: inline-block;
    font-size: 2.5rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
}

.css-4ljt47-MenuList{
    color: black;

}

#accordion{
    font-family: Rubik sans-serif;
    display: block;
    width: 90vw;
    margin: 1rem auto;
    background: rgba(255, 255, 255, 0.2);
    border: 2px solid var(--div-border);
    border-radius: .5rem;
    padding: 1rem 0;
    box-shadow: none;
    align-items: center
}
#accordion h2{
    margin: 1rem;
    font-size: 1.5rem;
}
#accordionDetails{
    display: block;
    margin: auto;
    width: 95%;
    padding: 1rem;
    border-radius: 2.3rem;
    border: white 1px solid;
    background-color: rgba(0, 0, 0, 0.12);
    overflow: auto;
}

.accordionGroup .MuiAccordionSummary-content{
    display: contents;
}
.searchIcon{
    margin: 0 .8rem;
    display: inline-block;
}

.accordionGroup{
    border: 3px solid rgba(255, 255, 255, 0.66);
    border-radius: 1.5rem;
    width: fit-content;
    display: block;
    margin: 3rem auto;
    padding: 1rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);

}
.accordionGroup .paramTitle{
    margin: .5rem;

}
.accordionGroup .paramTitle:before{
    content: url("../../images/banicon-param.png");

}

#panel-header p{
    color: var(--text);
    font-family: Rubik, serif;
    display: contents;
}
#panel-header{
    margin-left: 5rem;
}

.sector-creator input{
    border-radius: .5rem;
    width: 100%;
    box-sizing: border-box;
    box-shadow: inset rgba(0,0,0,0.1) 1px 2px 19px ;
    background-color: transparent;
    font-family: Rubik, sans-serif;
    font-size: 16px;
    padding: 14px 2px 12px 10px;
    border: 1px solid white;
}
.sector-creator:before{
    content: url("../../images/secteur.png");
}
.sector-creator{
    gap: 1rem;
    display: inline-flex;
    background-color: rgba(255, 255, 255, 0.39);
    padding: 1rem 2rem;
    border-radius: 1.5rem;
    margin: 1rem;
    align-items: center;
    height: 7rem;
    width: 40rem;
}

.riskAnaSelector:before{
    content: url("../../images/risk-ana.png");
}
.riskAnaSelector select{
    margin: auto 2rem;
    display: block;
    padding: .7rem;
    border-radius: .2rem;
    border: 1px solid grey;
    font-family: Rubik, sans-serif;
    font-size: 1.2rem;
    width: 10rem;
}
.riskAnaSelector{
    display: inline-flex;
    background-color: rgba(255, 255, 255, 0.39);
    padding: 1rem 2rem;
    border-radius: 1.5rem;
    margin: 1rem;
    align-items: center;
    height: 7rem;
    width: 40rem;
}


.styleSelector:before{
    content: url("../../images/theme-icon.png");
}

.styleSelector{
    display: inline-flex;
    background-color: rgba(255, 255, 255, 0.39);
    padding: 1rem 2rem;
    border-radius: 1.5rem;
    margin: 1rem;
    align-items: center;
    height: 7rem;
    width: 40rem;
}
.styleSelector select{
    margin: auto 2rem;
    display: flex;
    padding: .7rem;
    border-radius: .2rem;
    border: 1px solid grey;
    font-family: Rubik, sans-serif;
    font-size: 1.2rem;
    width: 16rem;
}

.miscParam{
    display: grid;
    margin: auto;
}

.sector-creator input{
    width: 30%;
    margin-left: 2rem;
    padding: .7rem;
    border-radius: .2rem;
    border: 1px solid grey;
}

.alertNoSiret p{
    font-weight: 800;
    margin: 1rem 2rem;
}
.alertNoSiret .inline{
    text-align: center;
}